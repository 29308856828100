<template>
    <busy v-if="busy" />
    <div v-else>
      <div class="row mb-3" v-if="payouts">
        <div class="col">
          <h5 class="fw-bold text-primary">Stripe Payouts</h5>
          <div class="card">
            <table class="table table-hover mb-0" v-if="payouts.length > 0">
                <thead>
                <tr>
                    <th scope="col" style="border-top: none;">Reference</th>
                    <th scope="col" style="border-top: none;">Amount</th>
                    <th scope="col" style="border-top: none;">Initiated</th>
                    <th scope="col" style="border-top: none;">Status</th>
                    <th scope="col" style="border-top: none;">Est. Arrival</th>
                    <th scope="col" style="border-top: none;"></th>
                </tr>
                </thead>
                <tbody>
                <tr class="cursor-pointer" v-for="payout in payouts">
                    <td>{{payout.id.substring(5)}}...</td>
                    <td>£{{(payout.amount / 100).toFixed(2)}}</td>
                    <td>{{payout.created | formatUnixTime}}</td>
                    <td class="text-capitalize">{{payout.status | formatStatus}}</td>
                    <td>{{payout.arrival_date | formatUnixDate}}</td>
                    <td class="cursor-pointer"><i class="far fa-arrow-alt-square-down"></i></td>
                </tr>
                </tbody>
            </table>
            <div v-else class="card-body">
                <div class="row">
                    <div class="col text-center">
                        <p><i class="far fa-info-circle fa-3x text-primary"></i></p>
                        <p>No payouts found.</p>
                    </div>
                </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="d-flex justify-content-between">
                <button class="btn btn-light"
                        :disabled="!hasPrev"
                        @click="fetchStripePayouts('prev')">
                  Prev Page
                </button>
                <button class="btn btn-light"
                        @click="fetchStripePayouts('next')">
                  Next Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ["path", "user"],
  data() {
    return {
        busy: true,
        payouts: null,
        startingPayoutId: null, //pagination tracking
        hasMore: false,
        hasPrev: false,
    };
  },
  methods: {
    fetchStripePayouts(direction=null){
      let url = process.env.VUE_APP_API_URL + '/settings/payments/fetch-stripe-payouts';

      if (direction === 'next') {
        this.hasPrev = true;
        const starting_after = this.payouts[this.payouts.length-1].id ?? this.startingPayoutId;
        url = this.updateQueryStringParameter(url, "starting_after", starting_after);
      } else if (direction === 'prev') {
        if (this.payouts[0]){
          url = this.updateQueryStringParameter(url, "ending_before", this.payouts[0].id);
        }
      }
      this.$axios.get(url, {})
          .then(({data}) => {
              this.payouts = data.data;
              this.hasMore = data.has_more;
              this.busy = false;

            if (this.payouts[0]) {
              this.startingPayoutId = this.startingPayoutId ?? this.payouts[0].id;
              this.hasPrev = this.payouts[0].id !== this.startingPayoutId;
            }
          });
    },
    updateQueryStringParameter(uri, key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = uri.indexOf("?") !== -1 ? "&" : "?";
      if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2");
      } else {
        return uri + separator + key + "=" + value;
      }
    },
  },
  mounted() {
      this.fetchStripePayouts();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
    formatUnixTime(unixTime) {
        return moment.unix(unixTime).format("LLL");
    },
    formatUnixDate(unixTime) {
      return moment.unix(unixTime).format("LL");
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    }
  },
};
</script>

<style>
</style>
