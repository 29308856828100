<template>
  <div class="mb-3">
    <h5 class="fw-bold text-primary">Swandoola Payments Payouts</h5>
    <busy :visible="loading" />
    <div v-if="!loading && transactions" class="card bg-light shadow-none" >
      <div class="card-body">

        <div class="card shadow-none">
          <table class="table mb-0"
                 v-if="transactions.accountTransactionLists[0].transactions.length > 0">
            <thead>
              <th scope="col" style="border-top: 0px">Reference</th>
              <th scope="col" style="border-top: 0px">Amount</th>
              <th scope="col" style="border-top: 0px">Date</th>
            </thead>
            <tbody>
            <tr v-for="t in transactions.accountTransactionLists[0].transactions">
              <td>{{ t.pspReference }}</td>
              <td>{{currencySymbols[t.amount.currency]}}{{ t.amount.value | formatCurrency }}</td>
              <td>{{ t.creationDate | formatDate }}</td>
            </tr>
            </tbody>
          </table>
          <div v-else class="card-body">
            <p class="text-center">
              No payouts found.
            </p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col text-end">
            <button class="btn btn-sm btn-outline-primary"
                    :disabled="!transactions.accountTransactionLists[0].hasNextPage"
            >
              <i class="far fa-arrow-right me-1" />
              Next Page
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col text-center">
          <p><i class="far fa-info-circle fa-3x text-primary"></i></p>
          <p>No payouts found.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      transactions: null
    };
  },
  methods: {
    fetchPayouts() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/settings/payments/adyen/payouts").then(({ data }) => {
        this.transactions = data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$stash.path;
    },
    currencySymbols() {
      return {
        'EUR': '€',
        'GBP': '£'
      }
    }
  },
  mounted() {
    this.fetchPayouts();
  },
  filters: {
    formatCurrency(val) {
      return Math.abs(val / 100).toFixed(2);
    },
    formatDate(date) {
      return moment(date).format("LLL");
    }
  },
};
</script>
