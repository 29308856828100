<template>
  <div class="container-fluid" v-if="$can('manage payment settings')">
    <h4 class="fw-bold text-primary">Payment Processing</h4>
    <div class="row" style="min-height: 88vh">
      <div class="col-md-3 spark-settings-tabs">
        <payments-nav></payments-nav>
      </div>

      <!-- Tab cards -->
      <div class="col-md-9 ps-0">
        <p class="alert alert-primary">
          <i class="far fa-info-circle me-1"></i>
          The first payout for new integrations is typically paid out 7 days after the first successful payment is received. This waiting period can be up to 14 days for businesses in certain industries. This initial payout timing requirement cannot be waived.
        </p>

        <stripe-payouts v-if="isStripeUser" />

        <adyen-payouts
            v-if="user.clinic.adyen_integration &&
      user.clinic.adyen_integration.setup_status !== 'pending'" />
      </div>
    </div>
  </div>

</template>

<script>
import PaymentsNav from "@/views/areas/payments/partials/PaymentsNav";
import StripePayouts from "./stripe-connect-partials/StripePayouts";
import AdyenPayouts from "./partials/AdyenPayouts";

export default {
  props: [],
  data() {
    return {
      showStripeConnect: true
    }
  },
  computed: {
    isStripeUser() {
      return (this.$store.getters['auth/user'].clinic.stripe_integration_id > 0)
    },
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  components: {
    StripePayouts,
    AdyenPayouts,
    PaymentsNav,
  }
};
</script>


<style scoped>

</style>
